import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BananesPreface = () => (
  <Layout>
    <SEO title="BANANES CITRONNEES suivies de CAPRICES Preface" />
    <h2 className='underline-title'>Préface</h2>
    <br /><br />
    <div className="table-responsive">
      <p className="properly-aligned">
        <span className="black-bold par-starting">" Non, la poésie est morte ! "</span>, s'écrient critiques littéraires. Pour renforcer leur assertion, ils affirment que
        grâce à la science, les rêves des hommes deviennent réalisables, que la science réserve des songes vrais et que
        l'imagination s'essoufle à concevoir.
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Ceux</span> qui ont défini la poésie avant nous pensent plutôt qu'elle est toujours omniprésente. Au fait, si les hommes
        ne s'étonnent plus de rien, ils voudraient toutefois préserver les dimensions humaines de leurs joies, de leurs
        peines, de leur folie, de leur révolte, de leur liberté et de leur langage. La poésie demeure un refuge de tous les
        jours; pour ce fait, elle n'est jamais morte!
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Il</span> est de nombreuses personnes qui, à toutes les époques de l'histoire, trouvent à travers le langage poétique, leur
        outil pour faire sentir et voir la présence des choses.
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Où</span> en est la poésie négro-africaine? s'interrogent de nombreuses personnes qui accordent crédit à la poésie. Elle
        cherche sa voie, estiment certains; tant et si bien que les thèmes s'usent, sont dépassés. March Rombaut, chargé
        de chroniques à la Radio Télévision Belge (RTB) pense que le souffle des Anciens est à reprendre; on est au temps
        de l'épopée. Période d'attente. On cherche sa place dans la société revivifiée. Un besoin de renouvellement se fait
        sentir.
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Vers</span> où se tourner? les uns se tournent vers la tradition orale, le retour aux sources! Naté Ngu-Mongala n'est pas
        de ceux là. Il n'est pas très attiré par le courant d'une poésie traditionnelle révélée et renovée par les Birago
        Diop, les Hampate Ba... Il s'oriente plutôt vers une poésie plus lyrique, il emboite librement les pas à ceux qui
        savent exalter leurs sentiments personnels (les Lamartines, Hugo...); une poésie où l'expression est spontanée, 
        poétique et d'un sentiment sincère, une poésie où finalement il se débat avec les éternels problèmes propres à tous
        les hommes et qui se posent d'une façon générale à chaque génération, éternels problèmes humains, quoi.
      </p>
      <p>
        <span className="black-bold">
        Si j'étais Le-Bon-Dieu<br/>
        Je réediterais le déluge biblique<br/>
        J'engloutirais toute la terre<br/>
        Afin qu'aucun homme n'y vive.<br/>
        </span>
      </p>
      <p className="properly-aligned">
        <span className="par-starting">C'est</span> là une poésie d'un caractère mystérieux, laissant déviner un tourment certain de l'âme. Écrite sous une forme
        de monologue, cette poésie nous laisse une impression de troublante préoccupation. Le coeur tracassé par une longue
        requête et n'en pouvant rien, impuissant devant cette poussiérisation de l'humain, le poète cède provisoirement à la
        force destructive des malfaiteurs en ces termes: "Opérez donc en paix". Son permanent souci de voir le monde comme il
        se doit ne s'arrête pas là. Il se confie dans <Link to="/indignation/">Indignation</Link> à Tout-Puissant.
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Mais,</span> détrompez-vous: Naté ne fait pas que s'indigner. Il décrit par endroits le tableau de son milieu de vie (<Link to="/bukavu-2/">Bukavu</Link>),
        vu dans ses plus beaux jours, dans le décor où se créent multiples images au coucher du soleil (<Link to="/le-soir-d-evenement/">Le soir d'événement</Link>). 
        Ici, Naté, avec quelle suprême poésie! peint cependant qu'il donne l'âme aux choses. Ce qui plaît, par endroits encore,
        c'est la souplesse et le rythme dans ses poèmes:
      </p>
      <p>
        <span className="black-bold">
        Là-bas<br/>
        Là-bas à l'horizon<br/>
        Où l'horizon s'embrume<br/>
        Enclavé des vapeurs funestes<br/>
        Relents miasmatiques...
        </span>
      </p>
      <p className="properly-aligned">
        <span className="par-starting">De</span> belles strophes chantantes qui évoquent si bien les sentiments divers qui inspirent au poète une <Link to="/vision-horizontale/">vision Horizontale</Link>.
      </p>
      <p className="properly-aligned">
       <span className="par-starting">Analyser</span> toute l'oeuvre poétique de Naté exige de nous une assimilation et un jugement se révélant de longue période.
        Les poèmes de Naté prennent leur source dans <Link to="/invocation/">Invocation</Link> qui demeure, à mon avis, havre du poète pour déverser tout ce
        qui s'est emmagasiné en lui: "le déversoir de mon trop-plein", affirme-t-il. Il serait souhaitable pour le lecteur, à 
        son tour, de suivre le poète dans son port retiré pour partager avec lui sa joie et ses peines. Au fait, la joie ou
        les peines du poète ne sont-elles pas quelque peu nôtres? Pour mieux la pénétrer, pour mieux se voir à travers ce miroir
        du langage qu'est la poésie, il est conseillé de gouter à ces "Bananes Citronnées", cuisinées par notre popotier qu'est 
        l'intarissable Naté Ngu-Mongala Vurutoro-te-Nzapa.
      </p>
      <p className="font-italic">
        Cikuru Batumike<br/>
        Journaliste<br />
        Membre de l'Adelf<br />
        (Association des Écrivains de Langue Française, Mer et Outre-Mer, Paris)
      </p>
    </div> 
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default BananesPreface
